<template>
  <div></div>
</template>

<script>
import FormPartMixin from "@/mixins/FormPartMixin.js";
export default {
  mixins: [FormPartMixin],
};
</script>
